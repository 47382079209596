import React from "react"
import { Box, Flex, Stack, UnorderedList, ListItem } from "@chakra-ui/react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Slider from "react-slick"
import "../../../utils/slick.css"
import "../../../utils/slick-theme.css"

import {
  VideoPlayer,
  ArrowLeft,
  ArrowRight,
  IntroText,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

import {
  Container,
  Layout,
  Main,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  SecondaryPagesHero,
  Seo,
  SidebarLinks,
} from "../../../components/elements"

const Event = ({ data, pageContext }) => {
  const page = data.datoCmsCancerEvent
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const settings = {
    adaptiveHeight: "true",
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArrowLeft color="white" />,
    nextArrow: <ArrowRight color="white" />,
  }

  return (
    <Layout>
      <Seo title={page.title} />

      <SecondaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </SecondaryPagesHero>

      <Container width="full">
        <Stack
          direction={["column", "column", "row"]}
          spacing={12}
          py={[9, 9, 12, 16]}
        >
          <Main width={["full", "full", "2 / 3"]}>
            {/* Content */}
            <Flex flexWrap="wrap">
              <Box width="full">
                {page.video && (
                  <Box sx={{ mb: 6 }}>
                    <VideoPlayer url={page.video.url} />
                  </Box>
                )}

                <IntroText>{page.introContent}</IntroText>

                <Box
                  sx={{ width: ["full"] }}
                  dangerouslySetInnerHTML={{
                    __html: page.contentNode.childMarkdownRemark.html,
                  }}
                />

                {page.images.length > 0 ? (
                  <Box
                    mb={6}
                    maxWidth="680px"
                    sx={{
                      ".slick-arrow": {
                        backgroundColor: "blackAlpha.600",
                      },
                    }}
                  >
                    <Slider {...settings}>
                      {page.images.map(({ gatsbyImageData, url }) => (
                        <Box
                          key={url}
                          sx={{
                            ".gatsby-image-wrapper-constrained": {
                              display: "block",
                            },
                          }}
                        >
                          <GatsbyImage image={gatsbyImageData} alt="slide" />
                        </Box>
                      ))}
                    </Slider>
                  </Box>
                ) : null}
              </Box>
            </Flex>
          </Main>

          <Box
            as="aside"
            id="sidebar"
            bg="lightgray"
            width={["full", "full", 1 / 3]}
          >
            <Box p={[3, 3, 6]}>
              <Box as="h3">Upcoming Events</Box>

              {/* <UnorderedList listStyleType="none" m={0} p={0}>
                {data.allDatoCmsCancerEvent.edges.map(({ node: event }) => (
                  <ListItem
                    key={event.id}
                    borderBottomColor="blackAlpha.200"
                    borderBottomStyle="solid"
                    borderBottomWidth={1}
                    m={0}
                    py={1}
                    sx={{ a: { textDecoration: "none" } }}
                  >
                    <Link to={`/giving/events/${event.slug}`}>
                      {event.title}
                    </Link>
                  </ListItem>
                ))}
                </UnorderedList> */}

              <SidebarLinks page={page} />
            </Box>
          </Box>
        </Stack>
      </Container>
    </Layout>
  )
}

export default Event

export const query = graphql`
  query eventPageQuery($slug: String!) {
    datoCmsCancerEvent(slug: { eq: $slug }) {
      id
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(imgixParams: { fit: "crop", crop: "focalpoint" })
      }
      meta {
        status
      }
      linksNode {
        childMarkdownRemark {
          html
        }
      }
      video {
        url
      }
      images {
        gatsbyImageData(
          layout: CONSTRAINED
          imgixParams: { fit: "crop", crop: "focalpoint" }
        )
        url
      }
      introContent
      seo {
        description
        title
        image {
          url
        }
      }
      title
      slug
    }
    allDatoCmsCancerEvent {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`
